window.SiteReady(($) => {
  console.log('Init Block: Page Header');

  let $items = $('.js-page_header');

  let onResize = window.Debounce(function() {
    $items.each((index, el) => {
      let $item = $(el);
      let $video = $item.find('.js-page_header_video');
      let ratio = $item.outerWidth() / $item.outerHeight();

      $video.removeClass('is_tall is_wide');

      if (ratio < (16 / 9)) {
        $video.addClass('is_tall');
      } else {
        $video.addClass('is_wide');
      }
    });

    $items.addClass('is_ready');
  });

  $(window).on('resize', () => {
    onResize();
  });

  onResize();

});